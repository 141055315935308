// ==============================
// Footer overrides
// ==============================

.front {
  footer {
    .region-footer {
      padding-top: $section-gutter / 1.6;
      border-top: $border;
    }
  }
}
